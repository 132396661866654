import {defineStore} from 'pinia';

export const useInternalStore = defineStore('internal', {
    state: () => ({
        show_catalog: false
    }),
    actions:{
        toggleCatalog(){
            this.show_catalog = !this.show_catalog;
        }
    },
    persist: true
})
