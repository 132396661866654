<script>
import {useCartStore} from "@/js/stores/cart.js";
import {useInternalStore} from "@/js/stores/internal.js";

export default {
    setup() {
        const cartStore = useCartStore();
        const internalStore = useInternalStore();
        return {
            cartStore,
            internalStore,
            productQty: cartStore.getProductCount
        };
    },
    name: "product-list",
    props: {
        products_list: {
            required: true,
            default: () => []
        }
    },
    methods: {
        addToCard(product) {
            this.cartStore.addProductToCart(product);
            if (window.innerWidth < 960) {
                this.$mitt.emit('show_order', true)
            }
        },
    }
}
</script>

<template>
    <v-row dense class="subcategory">
        <v-col
            cols="6"
            sm="4"
            md="4"
            :lg="internalStore.show_catalog? 4:3"
            v-for="product in products_list" :key="product.uid">
            <v-card @click="addToCard(product)" height="100%" class="product__card">
                <div class="card__wrapper d-flex flex-column">
                    <v-badge color="success" class="product__budge" v-if="productQty(product.uid)">
                        <template v-slot:badge>{{ productQty(product.uid) }}</template>
                    </v-badge>
                    <v-img
                        aspect-ratio="2.5"
                        src="https://picsum.photos/id/292/300/300"
                        cover
                    >

                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    <v-card-title class="card__title">
                        <span class="product-name">{{ product.name }}</span>
                        <span class="product-price">{{ product.price }} ₴</span>
                    </v-card-title>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<style lang="scss">
.product__card {
    position: relative;
    overflow: hidden;

    .product__budge {
        position: absolute;
        z-index: 2;
        right: 5px;
        top: 5px;

        .v-badge__badge {
            left: initial !important;
            bottom: initial !important;
            position: relative;
        }

    }
}

.subcategory {
    .card__wrapper {
        height: 100%;

        .v-card-title {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .product-name{
                white-space: initial;
                flex-grow: 1;
            }
        }
    }


    .product-price {
        font-weight: 700;
        text-align: left;

    }
}


</style>
