import {defineStore} from 'pinia';

class cartProduct {
    uid = null;
    count = 1;
    comment = "";
    product = {};
    constructor(count=1,product={}) {
        this.count = count;
        this.product = product
    }
    getSum(){
        return this.count*this.product.price;
    }
    assign(obj){
        Object.assign(this, obj)
        //this.product = this.product[0];
        return this;
    }
}

export const useCartStore = defineStore('cart', {
    state: () => ({
        /* var cartProduct[] */
        products: []
    }),
    actions: {
        addProductToCart(product) {
            if (!this.products.length) {
                let modelCartProduct = new cartProduct(1,product);
                //product.qty = 1;
                this.products.push(modelCartProduct)
            } else {
                let found = this.products.find(p => p.product.uid === product.uid);
                if (found) {
                    found.count++
                } else {
                    let modelCartProduct = new cartProduct(1,product);
                    //product.qty = 1;
                    this.products.push(modelCartProduct)
                }
            }
        },
        changeProductQty(type, product, qty) {
            //     plus or minus or manual
            let found = this.products.find(p => p.product.uid === product.product.uid);
            if (type === 'plus') {
                found.count++;
            }
            if (type === 'minus') {
                if (found.count > 1) {
                    found.count--
                } else {
                    this.removeProduct(found)
                }
            }
            if (type === 'manual') {
                found.count = qty;
            }

        },
        removeProduct(product) {
            let index = this.products.find(p => p.product.uid === product.product.uid);
            this.products.splice(index, 1);
        },
        addProductComment(product, comment) {
            let found = this.products.find(p => p.product.uid === product.product.uid);
            if (!found) {
                return;
            }
            found.comment = comment;
        },
        clear(){
            this.products = [];
        },
        assignProduct(arProduct){
            this.clear();
            let cart = []
            arProduct.map((p)=>{
               // p.count=1;
                cart.push(new cartProduct(p.count,p.product).assign(p))
            })
            this.products = cart;
            return this.products;
        }
    },
    getters: {
        getProducts() {
            return this.products;
        },
        getProductCount(state) {
            return (productId) => {
                let product = state.products.find(p => p.product.uid === productId);
                return product ? product.count : null;
            }
        },
        getCartTotalSum(state) {
            return state.products.reduce((sum, product) => {
                return sum + product.getSum();
            }, 0);
        },
        checkIfHasComment(state) {
            return (productId) => {
                let product = state.products.find(p => p.uid === productId);
                if (product.comment && product.comment.trim().length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    persist: true
});
