<script>
export default {
    name: "catalog-breadcrumbs",
    props: ['isEdit'],
    data() {
        return {
            breadcrumbs: [
                {
                    title: 'Категорії',
                    to: {name: this.isEdit ? 'edit-order-categories' : 'create-order-categories'},
                    disabled: false
                },
                {
                    title: 'Товари',
                    to: '',
                    disabled: true
                },

            ],
        }
    },
    created() {
        console.log(this.isEdit)
    },

    computed: {},
    methods: {}
}
</script>

<template>
    <div class="breadcrumbs__wrapper mt-1 d-md-none">
        <v-breadcrumbs class="pa-0 text-caption"
                       :items="breadcrumbs"
                       divider="•"
        >

            <template v-slot:title="{ item }">
                {{ item.title }}
            </template>
        </v-breadcrumbs>
    </div>

</template>

<style  lang="scss">
.breadcrumbs__wrapper {
    position: relative;
    z-index: 5;
    margin: 0 auto;

    .v-breadcrumbs .v-breadcrumbs-item {
        &:first-child {
            padding-left: 0 !important;
        }
    }
}
</style>
