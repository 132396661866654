<script>
export default {
    name: "edit-product-modal",
    data() {
        return {
            show_options: false,
            product: null,
            dialog: false
        }
    },
    created() {
        this.$mitt.on('show_product_option', this.showProductOptions)
    },
    methods: {
        showProductOptions(product) {
            this.show_options = true;
            this.product = product;
        },
        closeProductOptions() {
            this.show_options = false;
        }
    }
}
</script>

<template>
    <v-navigation-drawer
        v-model="show_options"
        location="left"
        height="100%"
        floating
        class="full-height-drawer"
        app
    >
        <template #prepend>
            <v-toolbar>
                <v-btn
                    class="font-weight-bold"
                    @click="closeProductOptions"
                >
                    <v-icon icon="arrow_back_ios"></v-icon>
                    Згорнути
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>
                    {{ product?.name || '' }}
                </v-toolbar-title>
            </v-toolbar>
        </template>
        <template #default>
            <v-row class="px-6">
                <v-col cols="12">
                    <v-card-title class="px-0">
                        {{ product?.name || '' }}
                    </v-card-title>
                </v-col>
            </v-row>
            <v-row class="px-6 ">
                <v-col>
                    <v-text-field
                        label="Коментар"
                        variant="underlined"
                        hide-details
                    >

                    </v-text-field>
                </v-col>
            </v-row>
            <v-row class="px-6 mt-4">
                <v-col class="qty__row d-flex ga-1 justify-space-between justify-sm-start ga-sm-3 flex-wrap">
                    <v-btn
                        variant="outlined"
                        color="blue"
                    >
                        -5
                    </v-btn>

                    <v-btn
                        variant="outlined"
                        color="blue"
                    >
                        -1
                    </v-btn>
                    <input type="text" :value="product?.qty || 0" class="qty__field">

                    <v-btn
                        variant="outlined"
                        color="blue"
                    >
                        +1
                    </v-btn>
                    <v-btn
                        variant="outlined"
                        color="blue"
                    >
                        +5
                    </v-btn>

                    <v-dialog
                        max-width="400"
                        persistent
                        v-model="dialog"
                        z-index="99999999999"
                        app
                    >
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn
                                v-bind="activatorProps"
                                variant="outlined"
                                color="red"
                                class="dialog__btn mt-sm-0 mt-3"
                            >
                                <v-icon icon="delete"></v-icon>
                            </v-btn>
                        </template>

                        <v-card
                            prepend-icon="info"
                            title="Видалити?"
                            :text="product?.name || ''"
                        >
                            <template v-slot:actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="dialog = false">
                                    Відмінити
                                </v-btn>

                                <v-btn
                                    variant="tonal"
                                    @click="dialog = false">
                                    Так
                                </v-btn>
                            </template>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </template>
        <!--        DELETE CONFIRM-->

    </v-navigation-drawer>

</template>

<style  lang="scss">
.full-height-drawer {
    z-index: 11111111 !important;
    transform: translateX(calc(-100% - 1000px)) !important;

    &.v-navigation-drawer--active {
        width: calc(100vw - 494px) !important;
        transform: translateX(0) !important;

    }

}

.qty__field {
    height: 36px;
    width: 64px;
    display: flex;
    align-self: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    border: 1px solid black;
}

@media screen and (max-width: 960px) {
    .full-height-drawer {
        width: 100% !important;
        max-width: 100% !important;
        transform: translateX(-100%) !important;

        &.v-navigation-drawer--active {
            transform: translateX(0) !important;
            width: 100% !important;
        }

        .v-toolbar-title {
            display: none;
        }
    }

}

@media screen and (max-width: 600px) {
    .dialog__btn {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .qty__row {
        .v-btn {
            padding:  0 5px !important;
            min-width: 40px;


        }

    }
}
</style>
