import {createRouter, createWebHistory} from 'vue-router';
import OrdersView from "@/js/views/OrdersView.vue";
import OrderView from "@/js/views/order/OrderView.vue";
import CategoryView from "@/js/views/order/catalog/CategoryView.vue";
import CategoryChildView from "@/js/views/order/catalog/CategoryChildView.vue";

const routes = [
    {
        path: '/',
        component: OrdersView,
        name: 'orders-list',
        meta: {
            title: 'Список замовлень',
        },
    },
    {
        path: '/orders/create',
        name: 'create-order',
        component: OrderView,
        props: {isEditMode: false},
        meta: {
            title: 'Нове замовлення',
            breadcrumb: 'Нове замовлення',
        },
        redirect: to => {
            return {name: 'create-order-categories'}
        },
        children: [
            {
                path: '/orders/create/category',
                name: 'create-order-categories',
                component: CategoryView,
                meta: {
                    title: 'Категорії',
                    breadcrumb: 'Категорії',
                },
            },
            {
                path: '/orders/create/category/:slug',
                name: 'create-order-products',
                component: CategoryChildView,
                meta: {
                    title: 'Продукти',
                    breadcrumb: 'Продукти',
                },
            },
        ],
    },
    {
        path: '/order/:id',
        name: 'edit-order',
        component: OrderView,
        props: {isEditMode: true},
        meta: {
            title: 'Редагування замовлення',
        },
        redirect: to => {
            return {name: 'edit-order-categories'}
        },
        children: [
            {
                path: '/order/:id/category',
                name: 'edit-order-categories',
                component: CategoryView,
                meta: {
                    title: 'Категорії',
                },
            },
            {
                path: '/order/:id/category/:slug',
                name: 'edit-order-products',
                component: CategoryChildView,
                meta: {
                    title: 'Продукти',
                },
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})
// Зміна мета-заголовка документа під час навігації
router.beforeEach((to, from, next) => {
    const defaultTitle = 'Orders';
    document.title = to.meta.title || defaultTitle;
    next();
});


export default router;
