<script>
import Cookies from 'js-cookie';

export default {
    name: "check-permission",
    props:{
      user_roles:{
          required:true,
      }
    },
    data() {
        return {
            dialog: false,
            token: null,
            valid: false,
            rules: {
                required: value => !!value || 'Поле обов\'язкове',
                role: value => (window.types.admin==value||window.types.items.filter((t)=>t.code==value).length) || 'Недійсна роль',
            },
        }
    },
    mounted() {
        this.$mitt.on('user-authorization', (flag) => {
            this.showDialog(flag);
        })
    },
    methods: {
        showDialog(flag) {
            this.dialog = !flag
        },
        setToken() {
            if (this.valid) {
                Cookies.set('token', this.token, {expires: 120});
                this.$mitt.emit('check-user-permissions');
                window.location.reload();
                setTimeout(()=>{
                    this.token = '';
                },1500);
            }
        }
    }
}
</script>

<template>
    <v-dialog
        v-model="dialog"
        class="authorization__dialog"
        max-width="400"
        persistent
    >
        <v-card
            prepend-icon="person"
            text="Введіть ідентифікатор користувача"
            title="Авторизація"
        >

            <template v-slot:text>
                <v-form v-model="valid" fast-fail @submit.prevent="setToken">
                    <v-text-field
                        class="mb-2"
                        label="Ідентифікатор користувача"
                        variant="underlined"
                        v-model="token"
                        :rules="[rules.required, rules.role]"
                        hide-details
                    >
                    </v-text-field>
                    <v-btn class="mt-6" color="primary" :disabled="!valid" type="submit" block>OK</v-btn>
                </v-form>
            </template>
        </v-card>
    </v-dialog>
</template>

<style lang="scss">
.authorization__dialog {
    .v-overlay__scrim {
        backdrop-filter: blur(10px);
        background: rgba(255, 255, 255, 0.3);
        opacity: 1;
    }
}
</style>
