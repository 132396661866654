<script>
import {useInternalStore} from "@/js/stores/internal.js";

export default {
    name: "CategoryView",
    setup() {
        const internalStore = useInternalStore();
        return {
            internalStore,
        };
    },
    props: {
        products: {
            type: Object,
            required: true,
            default: () => {
            }
        },
        isEditMode: {
            type: Boolean,
            required: true,
        }
    },
    created() {
    }
}
</script>

<template>
    <v-row dense class="category">
        <v-col  cols="6"
                sm="4"
                md="4"
                :lg="internalStore.show_catalog? 4:3"
               v-for="category in products.categories"
               :key="category.id">
            <v-card height="100%"
                    :to="{name:this.isEditMode?'edit-order-products':'create-order-products', params:{'slug':category.slug}}">
                <v-img
                    src="https://picsum.photos/id/292/300/300"
                    aspect-ratio="2.5"
                    cover
                >
                    <template v-slot:placeholder>
                        <v-row
                            align="center"
                            class="fill-height ma-0"
                            justify="center"
                        >
                            <v-progress-circular
                                color="grey-lighten-5"
                                indeterminate
                            ></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
                <v-card-title class="card__title">
                    <span class="product-name">{{ category.name }}</span>
                </v-card-title>
            </v-card>
        </v-col>
    </v-row>
</template>

<style scoped lang="scss">

</style>
