<script>
import Icon from "@/js/components/icon.vue";
import api from "@/js/api.js";

export default {
    name: "OrdersView",
    components: {Icon},

    data: () => ({
        dialog: false,
        dialogDelete: false,
        show_filters: false,
        show_created_from: false,
        show_created_to: false,
        show_date_from: false,
        show_date_to: false,
        is_mobile: false,
        headers: [
            {
                title: '№',
                align: 'start',
                key: 'id',

            },
            {
                title: 'Створено',
                key: 'created_at',
                class: 'd-none d-md-table-cell'

            },
            {
                title: 'На дату',
                key: 'date',
                class: 'd-none d-md-table-cell'
            },
            {
                title: 'Клієнт',
                key: 'client',
            },
            {
                title: 'Сума',
                key: 'sum',
                class: 'd-none d-md-table-cell'
            },
            {
                title: 'Статус',
                key: 'status',
                class: 'd-none d-md-table-cell'
            },
            {
                title: 'Дія',
                key: 'actions',
                sortable: false,
                class: 'd-none d-md-table-cell'
            },
        ],
        search: {
            id: '',
            client: '',
            created_from: null,
            created_to: null,
            date_from: null,
            date_to: null,
        },
        show_expand: false,
        items: [],
        expandedItems: [],
        statuses: [
            {
                key: 'completed',
                title: 'Виконаний',
                color: 'green'
            },
            {
                key: 'new',
                title: 'Новий',
                color: 'amber'
            },
            {
                key: 'in progress',
                title: 'В процесі',
                color: 'light-blue-accent-4'
            },
            {
                key: 'cancelled',
                title: 'Відмінений',
                color: 'red'
            },
        ],
        model:{
            page:1,
            onPage:20,
            orderBy:{numder:"desc"},
            filter:{}
}

    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        // Створено від
        computedCreatedFromFormatted() {
            return this.formatDate(this.search.created_from)
        },
        // Створено до
        computedCreatedToFormatted() {
            return this.formatDate(this.search.created_to)
        },
        // На дату від
        computedDateFromFormatted() {
            return this.formatDate(this.search.date_from)
        },
        // На дату до
        computedDateToFormatted() {
            return this.formatDate(this.search.date_to)
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    created() {
        this.initialize();


    },
    mounted() {
        this.is_mobile = window.innerWidth < 960;
        this.show_expand = window.innerWidth < 960;
        window.addEventListener('resize', () => {
            this.is_mobile = window.innerWidth < 960;
            this.show_expand = window.innerWidth < 960;
        })
    },


    methods: {
        async changeStatus(numberOrder, value) {
            try {
                let modelInst = this.items.filter((o) => o.number === numberOrder)[0];
                if (!modelInst.uid) {
                    return;
                }
                this.$mitt.emit('show-global-loader', true);
                let model = {number: modelInst.number, status: value};
                let response = await api.changeStatusOrder(model);
                this.$mitt.emit('show-global-loader', false);
            } catch (e) {
                console.error(e)
            }
            return true
        },
        async printOrder(numberOrder) {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.pdfOrder(numberOrder);
            let pdfData = response.data.data;
            this.$mitt.emit('show-global-loader', false);
            this.printCustomContent(pdfData)
        },
        printCustomContent(pdfData) {
            // Створюємо нове вікно
            var printWindow = window.open('', '', 'height=600,width=800');
            // Додаємо HTML-вміст, який хочемо надрукувати
            printWindow.document.write('<html><head><title>Друк</title>');
            printWindow.document.write('</head><body>');
            printWindow.document.write(`<iframe src="${pdfData}" width="100%" height="100%"></iframe>`);
            printWindow.document.write('</body></html>');
        },
        async deleteOrder(numberOrder) {
            this.$mitt.emit('show-global-loader', true);
            let response = await api.deleteOrder(numberOrder);
            if (response.data && response.data.status && response.data.status === true) {
                this.initialize();
            }
            this.$mitt.emit('show-global-loader', false);
        },
        expand(item) {
            // Method to toggle row expansion
            const index = this.expandedItems.indexOf(item.id);
            if (index === -1) {
                this.expandedItems.push(item.id);
            } else {
                this.expandedItems.splice(index, 1);
            }
        },
        // Функція для форматування дати у форматі дд-мм-рррр
        formatDate(date) {
            if (!date) return null;
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Місяці від 0 до 11
            const year = date.getFullYear();
            return `${day}.${month}.${year}`;
        },
        async getOrdersList() {
            try {
                let response = await api.getOrdersList();
                return response.data.data.items;

            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get orders error'
                })
            }
        },
        async initialize() {
            try {
                this.items = await this.getOrdersList()

            } catch (error) {
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get orders error'
                })
            }
        },
        clearSearchDate(field) {
            event.stopPropagation();
            this.search[field] = null;
        },

        editItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.desserts.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.desserts.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.desserts[this.editedIndex], this.editedItem)
            } else {
                this.desserts.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>

<template>
    <v-container fluid class="orders__page">

        <v-toolbar color="transparent" flat>
            <v-toolbar-title tag="h4" class="ml-0">Список замовлень</v-toolbar-title>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5"
                    >Are you sure you want to delete this item?
                    </v-card-title
                    >
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                        >Cancel
                        </v-btn
                        >
                        <v-btn
                            color="blue-darken-1"
                            variant="text"
                            @click="deleteItemConfirm"
                        >OK
                        </v-btn
                        >
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-toolbar color="transparent" flat class="mb-4">
            <v-menu
                v-model="show_filters"
                :close-on-content-click="false"
                location="bottom"
                offset="15"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        color="primary"
                        variant="tonal"
                        v-bind="props"
                        :prepend-icon=" !show_filters ? 'filter_alt':'close'"
                    >
                        Фільтр
                    </v-btn>
                </template>
                <v-card

                    class="pa-2 responsive__filter-card"
                >
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"

                                >
                                    <v-text-field
                                        v-model="search.id"
                                        label="ID"
                                        variant="underlined"
                                        hide-details="true"
                                    >

                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="show_created_from"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedCreatedFromFormatted"
                                                label="Створено від:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.created_from? 'close': ''"
                                                @click:appendInner="clearSearchDate('created_from')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"

                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.created_from"
                                            hide-header
                                            @update:modelValue="show_created_from = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="show_created_to"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedCreatedToFormatted"
                                                label="Створено до:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.created_to? 'close': ''"
                                                @click:appendInner="clearSearchDate('created_to')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.created_to"
                                            hide-header
                                            @update:modelValue="show_created_to = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="show_date_from"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedDateFromFormatted"
                                                label="На дату від:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.date_from? 'close': ''"
                                                @click:appendInner="clearSearchDate('date_from')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.date_from"
                                            hide-header
                                            @update:modelValue="show_date_from = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="6"
                                >
                                    <v-menu
                                        v-model="show_date_to"
                                        :close-on-content-click="false"
                                        location="bottom"
                                        offset="15"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-text-field
                                                v-model="computedDateToFormatted"
                                                label="На дату до:"
                                                hide-details="true"
                                                prepend-inner-icon="event"
                                                :append-inner-icon="search.date_to? 'close': ''"
                                                @click:appendInner="clearSearchDate('date_to')"
                                                readonly
                                                v-bind="props"
                                                variant="underlined"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.date_to"
                                            hide-header
                                            @update:modelValue="show_date_to = false">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-btn color="transparent" width="100%" prepend-icon="check">
                                        Застосувати
                                    </v-btn>

                                </v-col>
                                <v-col col="6">
                                    <v-btn color="red" width="100%" prepend-icon="close">
                                        Скинути
                                    </v-btn>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-form>
                </v-card>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn color="green-darken-1" variant="tonal" prepend-icon="add" :to="{name:'create-order'}">
                Нове замовлення
            </v-btn>
        </v-toolbar>

        <v-data-table
            :headers="headers"
            :items="items"
            :show-expand="show_expand"
            v-model:expanded="expandedItems"
            :expand="expand"
        >
            <template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
                <tr class="bg-grey-lighten-2">
                    <template v-for="column in columns" :key="column.key">
                        <td :class="column.class || ''">
                            <span class="mr-2 cursor-pointer font-weight-bold"
                                  @click="() => toggleSort(column)">{{ column.title }}</span>
                            <template v-if="isSorted(column)">
                                <v-icon :icon="getSortIcon(column)"></v-icon>
                            </template>
                        </td>
                    </template>
                </tr>
            </template>
            <template v-slot:item="{item, toggleExpand}">
                <tr>
                    <td>
                        <v-btn variant="text" color="blue"
                               :to="{name:'edit-order', params:{id: item.number}}"
                        >
                            {{ item.number }}
                        </v-btn>
                    </td>
                    <td v-if="!is_mobile">
                        {{ item.create_at }}
                    </td>
                    <td v-if="!is_mobile">
                        {{ item.date }}
                    </td>
                    <td>
                        {{ item.client.name }}
                    </td>
                    <td v-if="!is_mobile">
                        {{ item.sum }} грн
                    </td>
                    <td v-if="!is_mobile">
                        <v-select
                            class="status__select"
                            @update:modelValue="changeStatus(item.number,$event)"
                            :items="statuses"
                            :model-value="item.status"
                            v-model="item.status"
                            item-title="title"
                            item-value="title"
                            variant="plain"
                            density="compact"
                            max-width="150"
                            width="fit-content"
                            chips
                            hide-selected
                            hide-details="true"
                            flat
                        >
                            <template #chip="{item, idx, props}">

                                <v-chip :color="item.raw.color">
                                    {{ item.title }}
                                </v-chip>

                            </template>
                        </v-select>
                    </td>
                    <td v-if="!is_mobile">
                        <v-menu
                            :close-on-content-click="false"
                            location="left"
                            offset="10"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    color="primary"
                                    icon="more_horiz"
                                    v-bind="props"
                                >
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-item>
                                        <v-btn
                                            width="100%"
                                            class="tex"
                                            :to="{name:'edit-order', params:{id: item.number}}"
                                            prepend-icon="visibility"
                                        >
                                            Переглянути
                                        </v-btn>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-dialog
                                            max-width="400"
                                            persistent

                                            z-index="99999999999"
                                            app
                                        >
                                            <template v-slot:activator="{ props: activatorProps }">
                                                <v-btn
                                                    v-bind="activatorProps"
                                                    width="100%"
                                                    class="tex"
                                                    prepend-icon="delete"
                                                    :disabled="['Новый','Новий'].indexOf(item.status)==-1"
                                                >
                                                    Видалити
                                                </v-btn>
                                            </template>
                                            <template v-slot:default="{isActive}">
                                                <v-card
                                                    prepend-icon="info"
                                                    title="Видалити замовлення?"
                                                >
                                                    <template v-slot:actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn @click="isActive.value = false">
                                                            Відмінити
                                                        </v-btn>
                                                        <v-btn
                                                            variant="tonal"
                                                            @click="deleteOrder(item.number)"
                                                        >
                                                            Так
                                                        </v-btn>
                                                    </template>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </v-list-item>
                                    <v-list-item>
                                        <v-btn
                                            width="100%"
                                            class="tex"
                                            prepend-icon="print"
                                            @click="printOrder(item.number)"
                                        >
                                            Друкувати
                                        </v-btn>
                                    </v-list-item>
                                </v-list>
                            </v-card>

                        </v-menu>
                    </td>
                    <td v-if="is_mobile">
                        <v-btn @click="expand(item)" size="small" icon="keyboard_arrow_down" variant="text">
                        </v-btn>
                    </td>
                </tr>
            </template>
            <template v-slot:expanded-row="{ columns, item }">
                <tr>
                    <td :colspan="columns.length">
                        <v-table>
                            <tbody>
                            <tr>
                                <td><b>Створено</b></td>
                                <td>{{ item.created_at }}</td>
                            </tr>
                            <tr>
                                <td><b>На дату</b></td>
                                <td>{{ item.date }}</td>
                            </tr>
                            <tr>
                                <td><b>Сума</b></td>
                                <td>{{ item.sum }} грн</td>
                            </tr>
                            <tr>
                                <td><b>Статус</b></td>
                                <td>
                                    <v-select
                                        class="status__select"
                                        @update:modelValue="changeStatus(item.number,$event)"
                                        :items="statuses"
                                        :model-value="item.status"
                                        item-title="title"
                                        item-value="key"
                                        variant="plain"
                                        density="compact"
                                        max-width="150"
                                        width="fit-content"
                                        chips
                                        hide-selected
                                        hide-details="true"
                                        flat

                                    >
                                        <template #chip="{item, idx, props}">

                                            <v-chip :color="item.raw.color">
                                                {{ item.title }}
                                            </v-chip>

                                        </template>
                                    </v-select>
                                </td>
                            </tr>
                            </tbody>
                        </v-table>
                        <v-row class="pb-3 mt-3">
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    :to="{name:'edit-order', params:{id: item.number}}"
                                >
                                    <v-icon icon="visibility"></v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    @click="deleteOrder(item.number)"
                                >
                                    <v-icon icon="delete"></v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="4">
                                <v-btn
                                    width="100%"
                                    class="tex"
                                    @click="printOrder(item.number)"
                                >
                                    <v-icon icon="print"></v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </td>
                </tr>


            </template>
            <template v-slot:no-data>
                У вас ще немає замовлень
            </template>
        </v-data-table>

    </v-container>
</template>

<style lang="scss">
.status__select {
    .v-field__append-inner {
        display: none !important;
    }
}

.responsive__filter-card {
    min-width: 500px;
}

@media screen and (max-width: 600px) {
    .responsive__filter-card {
        min-width: 300px;
    }
}

.mob__table-info {
    //display: none;
}
</style>
