<script>
import {tr} from "vuetify/locale";

export default {
    name: "breadcrumbs",
    data(){
        return{
            breadcrumbs: [
                {
                    text: 'Home',
                    route_name: 'home',
                    disabled:false,

                },
                {
                    text: 'Список замовлень',
                    route_name: 'orders-list',
                    disabled: true
                },
            ]
        }
    }
}
</script>

<template>
<!--    <div class="breadcrumbs__wrapper">-->
<!--        <v-breadcrumbs >-->

<!--            &lt;!&ndash; Елементи хлібних крихт &ndash;&gt;-->
<!--            <template  v-for="(breadcrumb, index) in breadcrumbs"-->
<!--                       :key="index">-->
<!--            <v-breadcrumbs-item-->

<!--                :to="{name:breadcrumb.route_name}"-->
<!--                :disabled="breadcrumb.disabled"-->
<!--                exact-->
<!--                :title="breadcrumb.text"-->
<!--            />-->
<!--              <v-breadcrumbs-divider v-if="index < breadcrumbs.length - 1">/</v-breadcrumbs-divider>-->
<!--            </template>-->
<!--        </v-breadcrumbs>-->
<!--    </div>-->

</template>

<style scoped lang="scss">
.breadcrumbs__wrapper{
    margin: 0 auto;
}
</style>
