<script>
import api from "@/js/api.js";
import ProductList from "@/js/components/product-list.vue";

export default {
    name: "CategoryChildView",
    components: {ProductList},
    props:['products'],
    data() {
        return {
            products2: [],
            slug: null
        }
    },


    created() {
        this.$watch(
            () => this.$route.params.slug,
            (slug)=>{
                this.slug = slug;
                this.getProducts(slug)
            },
            // fetch the data when the view is created and the data is
            // already being observed
            { immediate: true }
        )

    },
    watch:{
        products:{
            handler(newValue, oldValue) {
                this.getProducts(this.slug);
            },
            deep: true
        }
    },
    methods: {
         getProducts(slug) {
            try {
                this.products2 = this.products?.categories?.filter((cc)=>cc.slug===slug)[0]['products'];
            } catch (e) {
                console.error(e)
                this.$mitt.emit('show_msg', {
                    msg: 'Get category products error'
                })
            }
        },
    }
}
</script>

<template>
    <product-list :products_list="products2"></product-list>
</template>
