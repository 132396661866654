<script>
import { defineAsyncComponent } from 'vue'
export default {
    name: "icon",
    props: {
        "name":{
            default: 'default'
        },
        className:{
            default: ''
        }
    },
    data () {
        return {
            currentIcon: null
        }
    },
    computed: {
        icon() {
            return defineAsyncComponent(() => import(`@/assets/svg/${this.name}.svg`))
        }
    },
}
</script>

<template>
    <component :is="icon" :class="className"/>
</template>
