// Vuetify
import "vuetify/styles";
import {createVuetify} from "vuetify";
import {VDateInput} from 'vuetify/labs/VDateInput'
import "material-design-icons-iconfont/dist/material-design-icons.css"
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import {aliases, md} from 'vuetify/iconsets/md'
import { uk } from 'vuetify/locale'

const customeTheme = {
    dark: false,
    colors: {
        primary: "#012d5c",
        yellow: "#ffe575",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        lightblue: "#14c6FF",
        pink: "#FF1976",
        orange: "#FF8657",
        magenta: "#C33AFC",
        darkblue: "#1E2D56",
        gray: "#909090",
        neutralgray: "#9BA6C1",
        green: "#2ED47A",
        red: "#FF5c4E",
        darkblueshade: "#308DC2",
        lightgray: "#BDBDBD",
        lightpink: "#FFCFE3",
        white: "#FFFFFF",
        muted: "#6c757d",
    },
};

const vuetify = createVuetify({
    components: {
        ...components,
        VDateInput
    },
    directives,
    icons: {
        defaultSet: 'md',
        aliases,
        sets: {
            md,
        },
    },
    locale:{
        locale: 'uk',
        fallback:'uk',
        messages: { uk },
    },
    theme: {
        defaultTheme: "customeTheme",
        themes: {
            customeTheme,
        },
    },
});

export default vuetify;
